<template>
  <div>
    <div class="smz-table-name">Диапазон оплаты труда по бендам/проф.группам</div>

    <table class="smz-base-table" v-loading="loading">
      <thead>
      <tr>
        <th>Бенд</th>
        <th>Разряд</th>
        <th>Локация</th>
        <th>Min</th>
        <th>Middle</th>
        <th>Max</th>
      </tr>
      </thead>
      <tbody>
      <tr
        v-for="range in ranges"
      >
        <td class="smz-base-table-cell-gray">{{range.band}}</td>
        <td class="smz-base-table-cell-gray">{{range.rank}}</td>
        <td class="smz-base-table-cell-gray">{{range.location}}</td>
        <td class="smz-base-table-cell-gray">{{range.min}}</td>
        <td class="smz-base-table-cell-gray">{{range.middle}}</td>
        <td class="smz-base-table-cell-gray">{{range.max}}</td>

      </tr>
      </tbody>
    </table>

    <paginator
      :paginator.sync="paginator"
      @page-changed="getSalaryRanges"
    ></paginator>

  </div>
</template>

<script>

import requestSender from "@/api/base/requestSender";
import ClickToEdit from "@/components/ClickToEdit.vue";
import {paginator} from "@/mixins/paginator";
import Paginator from "@/components/Paginator.vue";

export default {
  name: "CompensationProcedureRaiseRssRangesByBands",
  components: {Paginator, ClickToEdit},
  mixins:[paginator],
  props:{
    procedure: {type: Object, required: true},
  },


  data() {

    return {
      loading: false,
      ranges: [],
    }
  },

  mounted() {
    this.getSalaryRanges();
  },
  methods: {
    getSalaryRanges(){
      this.loading = true;
      requestSender('get', 'compensation-procedure-raise-rss/get-salary-ranges', {
        procedure_id: this.procedure.id,

        // [this.sorter.param]: this.sorter.sort,
        [this.paginator.param]: this.paginator.page,
        [this.paginator.paramPerPage]: this.paginator.perPage,
      })
        .then(({data, paginator, sorter}) => {
          this.ranges = data.ranges;
          this.paginatorFill(paginator);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },



}
</script>
